// ROI calc data object 

const roiDefaultData = {
    prefix: '',
    hsa: null, 
    type: '', 
    coinsurance: 0,
    oopm: {
      min: null,
      dyn: null, 
      max: '',
    },
    plans: {
				0: null, 
				500: null, 
				1000: null, 
				1500: null, 
				2000: null, 
				2500: null, 
				3000: null, 
				3500: null, 
				4000: null, 
				4500: null, 
				5000: null, 
				5500: null, 
				6000: null, 
				6500: null, 
				7000: null, 
				7500: null, 
				8000: null, 
				8500: null, 
				9000: null, 
			},
			features: {
				preventativecare: null,
				virtualcare: null,
				rxcoverage: null,
				hsacompatible: null,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 0,
				maxVal: 9000,
			}
		}

const roiPlanData = [
		{
			prefix: 'H',
			hsa: true,
			type: 'HSA Compatible', 
			coinsurance: 100,
			oopm: {
				min: null,
				dyn: 1, 
				max: null,
			},
			plans: {
				0: null, 
				500: null, 
				1000: null, 
				1500: null, 
				2000: null, 
				2500: null, 
				3000: null, 
				3500: 'H35', 
				4000: 'H40', 
				4500: 'H45', 
				5000: 'H50', 
				5500: 'H55', 
				6000: 'H60', 
				6500: 'H65', 
				7000: 'H70', 
				7500: 'H75', 
				8000: null, 
				8500: null, 
				9000: null, 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: true,
				primarycare: 'Employee pays 100% before deductible',
				specialtycare: 'Employee pays 100% before deductible',
				physicaltherapy: 'Employee pays 100% before deductible',
				chiropractic: 'Employee pays 100% before deductible',
				minVal: 3500,
				maxVal: 7500,
			}
		},
		{
			prefix: 'S',
			hsa: false,
			type: 'Superior', 
			coinsurance: 10,
			oopm: {
				min: 2500,
				dyn: 2, 
				max: 5000,
			},
			plans: {
				0: 'S00', 
				500: 'S05', 
				1000: 'S10', 
				1500: 'S15', 
				2000: 'S20', 
				2500: 'S25', 
				3000: 'S30', 
				3500: 'S35', 
				4000: 'S40', 
				4500: 'S45', 
				5000: null, 
				5500: null, 
				6000: null, 
				6500: null, 
				7000: null, 
				7500: null, 
				8000: null, 
				8500: null, 
				9000: null, 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: false,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 0,
				maxVal: 4500,
			}
		},
		{
			prefix: 'P',
			hsa: false,
			type: 'Premium', 
			coinsurance: 20,
			oopm: {
				min: 2500,
				dyn: 2, 
				max: 6000,
			},
			plans: {
				0: null, 
				500: 'P05', 
				1000: 'P10', 
				1500: 'P15', 
				2000: 'P20', 
				2500: 'P25', 
				3000: 'P30', 
				3500: 'P35', 
				4000: 'P40', 
				4500: 'P45', 
				5000: 'P50', 
				5500: null, 
				6000: null, 
				6500: null, 
				7000: null, 
				7500: null, 
				8000: null, 
				8500: null, 
				9000: null, 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: false,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 500,
				maxVal: 5000,
			}
		},
		{
			prefix: 'E',
			hsa: false,
			type: 'Essential', 
			coinsurance: 30,
			oopm: {
				min: null,
				dyn: null, 
				max: 7500,
			},
			plans: {
				0: null, 
				500: null, 
				1000: 'E10', 
				1500: 'E15', 
				2000: 'E20', 
				2500: 'E25', 
				3000: 'E30', 
				3500: 'E35', 
				4000: 'E40', 
				4500: 'E45', 
				5000: 'E50', 
				5500: 'E55', 
				6000: 'E60', 
				6500: 'E65', 
				7000: null, 
				7500: null, 
				8000: null, 
				8500: null, 
				9000: null, 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: false,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 1000,
				maxVal: 6500,
			}
		},
		{
			prefix: 'B',
			hsa: false,
			type: 'Basic', 
			coinsurance: 40,
			oopm: {
				min: null,
				dyn: null, 
				max: 8500,
			},
			plans: {
				0: null, 
				500: null, 
				1000: null, 
				1500: 'B15', 
				2000: 'B20', 
				2500: 'B25', 
				3000: 'B30', 
				3500: 'B35', 
				4000: 'B40', 
				4500: 'B45', 
				5000: 'B50', 
				5500: 'B55', 
				6000: 'B60', 
				6500: 'B65', 
				7000: 'B70', 
				7500: 'B75', 
				8000: 'B80', 
				8500: 'B85', 
				9000: null, 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: false,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 1500,
				maxVal: 8500,
			}
		},
		{
			prefix: 'C',
			hsa: false,
			type: 'Core', 
			coinsurance: 50,
			oopm: {
				min: null,
				dyn: null, 
				max: 9100,
			},
			plans: {
				0: null, 
				500: null, 
				1000: null, 
				1500: null, 
				2000: null, 
				2500: null, 
				3000: 'C30', 
				3500: 'C35', 
				4000: 'C40', 
				4500: 'C45', 
				5000: 'C50', 
				5500: 'C55', 
				6000: 'C60', 
				6500: 'C65', 
				7000: 'C70', 
				7500: 'C75', 
				8000: 'C80', 
				8500: 'C85', 
				9000: 'C90', 
			},
			features: {
				preventativecare: true,
				virtualcare: true,
				rxcoverage: true,
				hsacompatible: false,
				primarycare: '$25',
				specialtycare: '$50',
				physicaltherapy: '$25',
				chiropractic: '$25',
				minVal: 3000,
				maxVal: 9000,
			}
		}
	]

export { roiPlanData, roiDefaultData } 
