/**
* UTM PARAM GRABBER
* Persistently keep UTM params in URL even after navigating to new page 
*/

(function($) {

  // UTM params 
  $(document).ready(function() {

    // let newParams = new URLSearchParams(document.location.search);
    // const paramArray = ['utm, campaignid, adgroupid, gclid, REFERRALCOD, RR_WCID, RR_WCID_TTLW'];
    // const hasParams = paramArray.reduce(
    //   (accumulator, currentValue) => accumulator || currentValue,
    //   false
    //   );
    // console.log(hasParams);

    var cr_req = window.location.href;
    var temp_params_string = '';
    var temp_params_parts = '';
    var temp_href = '';

    // If URL contains '?' and NOT '?s', get relevant UTM params and attach 
    // them to all links on page except those with the class .utmIgnoreLink 
    // The '?s' param is used by WordPress for search, so this is a compromise 
    // to get the search and UTM params to coexist without breaking one another 
    if( cr_req.search(/\?/) > -1 && cr_req.search(/\?s/) < 0 && ( // include '?' and exclude '?s'
        cr_req.search('utm') > -1 || 
        cr_req.search('utm_campaign') || 
        cr_req.search('utm_medium') || 
        cr_req.search('utm_source') || 
        cr_req.search('utm_term') || 
        cr_req.search('utm_content') || 
        cr_req.search('last%20source') || 
        cr_req.search('campaignid') || 
        cr_req.search('adgroupid') || 
        cr_req.search('person%20source') || 
        cr_req.search('gclid') || 
        cr_req.search('msclkid') || 
        cr_req.search('channelpartner') ) ) {

      var params = cr_req.split('?')[1];

      $("a:not('.utmIgnoreLink')").each(function() {
        if( $(this).attr('href') != '#' && $(this).attr('href') != null ) { 

          if( $(this).attr('href').search(/\?/) < 0 ) { // if URL doesn't have '?' params, add them 
            $(this).attr('href', $(this).attr('href') + '?' + params );
          } 

        }
        // alert($(this).attr('href'));
        // console.log('window param found');
      });
    }

  });

})(jQuery);




/**
* MARKETO FORM DATA SCRAPER 
* This script checks if marketoForm element exists, then 
* pulls data from fields if employeeCount equals less than 5.
* Form is on https://www.sanabenefits.com/get-quote/, but this 
* should work anywhere as long as the form class and input IDs match.
*/
window.onload = function() {
    const makeFieldForMarketoInput = (fieldId, data) => {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = fieldId;
        field.id = fieldId;
        field.value = data[fieldId];
        return field;
    };

    const redirectWithPost = (data) => {
        const postForm = document.createElement('form');
        postForm.method = 'POST';
        postForm.action = 'https://secure.sanabenefits.com/digital-sales';
        postForm.style.display = 'none';

        Object.keys(data).forEach(fieldId => {
            const field = makeFieldForMarketoInput(fieldId, data);
            postForm.appendChild(field);
        });

        document.body.appendChild(postForm);
        postForm.submit();
        document.body.removeChild(postForm);
    };

    // Attempt to select the Marketo form
    const marketoForm = document.getElementById('mktoForm_1071');

    // Check if the Marketo form is present
    if (marketoForm) {
        marketoForm.addEventListener('submit', evt => {
            evt.preventDefault();

            const employeeCount = document.getElementById('EE_Size_Range__c').value;

            const data = {
                firstName: document.getElementById('FirstName').value,
                lastName: document.getElementById('LastName').value,
                email: document.getElementById('Email').value,
                company: document.getElementById('Company').value,
                phone: document.getElementById('Phone').value,
            };

            if (employeeCount === 'Less than 5') {
                localStorage.setItem('formData', JSON.stringify(data)); // Storing data in localStorage before redirecting
                redirectWithPost(data);
            }
        });
    }
};





// const pantheonUnstripped = () => {
//   // utility to transform param entries into object
//   const paramsToObject = (entries) => {
//     const result = {}
//     for(const [key, value] of entries) {
//       result[key] = value;
//     }
//     return result;
//   }

//   // get params from url
//   const searchParams = new URLSearchParams(window.location.search)
//   // store params as object
//   const paramsObj = paramsToObject( searchParams.entries() )

//   // collect all forms on the page
//   const forms = document.querySelectorAll('form')

//   let updatedFields = []

//   // for each form, find hidden fields and update the values
//   forms.forEach((form) => {
//     const hiddenFields = form.querySelectorAll('input[type="hidden"]')

//     hiddenFields.forEach((hidden) => {
//       // using the Formidable invalid message to identify field without field id.
//       // if no invalid message, abort.
//       if (!hidden.dataset.invmsg) { return }

//       // grab the param from the invalid message
//       const tag = hidden.dataset.invmsg.replace(/(is invalid)/gm, '').trim()
//       // if param is in the object of active params, update the frmval value.
//       if ( tag in paramsObj ) {
//         updatedFields = [...updatedFields, `${tag}: ${paramsObj[tag]}`]
//         hidden.value = hidden.dataset.frmval = paramsObj[tag]
//       }
//     })
//   })
//   console.warn('The following form fields have been updated: ', {updatedFields})
// }

// (() => {
//   pantheonUnstripped()
// })();







// Log to indicate the script is about to load
console.log('Pantheon script about to load...');

// Map field IDs to UTM parameters
const fieldIdToUtmParam = {
  'nf-field-92': 'utm_campaign',
  'nf-field-93': 'utm_medium',
  'nf-field-94': 'utm_source',
  'nf-field-95': 'utm_term',
  'nf-field-96': 'utm_content'
};

// Function to be called when the Ninja Form is detected
const pantheonUnstripped = () => {
  console.log('PantheonUnstripped function is running after detecting Ninja Form...');

  // Retrieve UTM params from localStorage
  const getUtmFromLocalStorage = () => {
    console.log('Retrieving UTM params from localStorage...');
    const utmParams = {};
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('utm_')) {
        utmParams[key] = localStorage.getItem(key);
        console.log(`Found ${key} in localStorage: ${utmParams[key]}`);
      }
    });
    console.log('All retrieved UTM parameters:', utmParams);
    return utmParams;
  };

  // Recursive function to search for hidden fields
  const findHiddenFieldsRecursively = (element, foundFields = []) => {
    if (element.nodeName === 'INPUT' && element.type === 'hidden') {
      foundFields.push(element);
    }

    Array.from(element.children).forEach((child) => {
      findHiddenFieldsRecursively(child, foundFields);
    });

    return foundFields;
  };

  // Populate form fields with UTM params from localStorage
  const populateFieldsWithUtm = (utmParams) => {
    // Collect all forms on the page that are inside .ninjaForm
    const ninjaForms = document.querySelectorAll('.ninjaForm form');
    console.log(`Ninja Forms found on page: ${ninjaForms.length}`);

    let updatedFields = [];

    // For each Ninja Form, find hidden fields and update the values
    ninjaForms.forEach((form, formIndex) => {
      console.log(`Processing Ninja Form ${formIndex + 1} of ${ninjaForms.length}`);

      // Use recursive function to find all hidden fields
      const hiddenFields = findHiddenFieldsRecursively(form);
      console.log(`Hidden fields found in Ninja Form ${formIndex + 1}: ${hiddenFields.length}`);

      hiddenFields.forEach((hidden, fieldIndex) => {
        console.log(`Processing hidden field ${fieldIndex + 1} of ${hiddenFields.length}:`, hidden);

        const fieldId = hidden.id || hidden.name;
        console.log(`Field ID or Name: ${fieldId}`);

        // Check if this field is one of the UTM-related hidden fields
        if (fieldIdToUtmParam[fieldId]) {
          const utmParam = fieldIdToUtmParam[fieldId];
          console.log(`Mapped ${fieldId} to UTM parameter ${utmParam}`);

          if (hidden.value === "PANTHEON_STRIPPED" && utmParams[utmParam]) {
            console.log(`Replacing "PANTHEON_STRIPPED" with ${utmParams[utmParam]} for ${fieldId}`);
            hidden.value = utmParams[utmParam];
            updatedFields.push(`${fieldId}: ${utmParams[utmParam]}`);
          }
        }
      });
    });

    if (updatedFields.length > 0) {
      console.warn('The following Ninja Form fields have been updated: ', { updatedFields });
    } else {
      console.warn('No Ninja Form fields were updated.');
    }
  };

  // Retrieve UTM parameters from localStorage
  const storedUtmParams = getUtmFromLocalStorage();

  // Populate form fields with UTM parameters from localStorage
  populateFieldsWithUtm(storedUtmParams);
};

// Use MutationObserver to detect when Ninja Forms are added to the DOM
const observer = new MutationObserver((mutationsList) => {
  for (let mutation of mutationsList) {
    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
      const ninjaForm = document.querySelector('.ninjaForm form');
      if (ninjaForm) {
        console.log('Ninja Form detected. Running pantheonUnstripped...');

        // Wait for 2 seconds after the form is detected to ensure fields are fully rendered
        setTimeout(() => {
          observer.disconnect(); // Stop observing once the Ninja Form is detected
          pantheonUnstripped();
        }, 2000);
      }
    }
  }
});

// Start observing the document body for changes (e.g., when Ninja Forms are added)
observer.observe(document.body, { childList: true, subtree: true });










/**
* NINJA FORMS HIDDEN FIELD VALUES
* This script checks if .nf-form-content element exists, and then retrieves the UTM 
* parameter value from the URL and passes it to corresponding hidden input field value.
* Form is currently located at https://www.sanabenefits.com/landing-page-demo/
*/
// Modify the UTM parameters early
// document.addEventListener("DOMContentLoaded", function () {
//     console.log("DOM fully loaded and parsed.");

//     // Add timing delay before running the script to let GTM do whatever it's doing
//     setTimeout(function () {
//         console.log("Starting script execution after delay.");

//         // Check if the form wrapper div .ninjaForm exists
//         const formWrapper = document.querySelector('.ninjaForm');
//         if (formWrapper) {
//             console.log(".ninjaForm exists, executing script.");

//             // Function to get the value of a URL parameter by name
//             function getParameterByName(name, url = window.location.href) {
//                 name = name.replace(/[\[\]]/g, '\\$&');
//                 const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
//                 const results = regex.exec(url);
//                 if (!results) return null;
//                 if (!results[2]) return '';
//                 return decodeURIComponent(results[2].replace(/\+/g, ' '));
//             }

//             // List of UTM parameters to capture
//             const utmParams = [
//               'utm_source', 
//               'utm_medium', 
//               'utm_campaign', 
//               'utm_term', 
//               'utm_content', 
//               'last source', 
//               'campaignid', 
//               'adgroupid', 
//               'person source', 
//               'gclid', 
//               'msclkid', 
//               'channelpartner', 
//               'mqld'
//             ];

//             console.log("UTM parameters list defined.");

//             // Automatically populate hidden fields for each UTM parameter
//             utmParams.forEach(param => {
//                 const value = getParameterByName(param);
//                 console.log(`Parameter: ${param}, Value: ${value}`);
//                 if (value) {
//                     const hiddenField = document.querySelector(`input[name="${param}"]`);
//                     if (hiddenField) {
//                         console.log(`Updating hidden field with name '${param}' to value '${value}'`);
//                         hiddenField.value = value;
//                         console.log(`Hidden field with name '${param}' updated to value '${hiddenField.value}'`);
//                     } else {
//                         console.log(`Hidden field with name '${param}' not found.`);
//                     }
//                 }
//             });

//         } else {
//             console.log(".ninjaForm does not exist, script will not execute.");
//         }

//     }, 7000); // 7-second delay
// });

















/**
* MARKETO FORM DATA SCRAPER - Part 1 of 2 
* This first script will check if the .mktoForm element exists, 
* and if it does, pull data from input fields in inputIds variable.
* Form used for testing is on https://www.sanabenefits.com/get-quote/, but 
* this should work anywhere as long as the form class and input IDs match.
*/
// window.onload = function() {
//     // Check for the presence of .mktoForm div
//     var mktoForm = document.querySelector('.mktoForm');

//     if (!mktoForm) {
//         return; // Exit the script if .mktoForm is not present
//     }

//     // Helper function to store values in localStorage
//     function storeInLocalStorage(fieldName, fieldValue) {
//         localStorage.setItem(fieldName, fieldValue);
//         // console.log(fieldName + " stored in localStorage: " + fieldValue);
//     }

//     var inputIds = ["Email", "Phone", "FirstName", "LastName", "Company"]; // Ids for relevant fields 

//     // For each input field, watch for changes and store the data for later use 
//     inputIds.forEach(function(id) {
//         var inputElement = document.getElementById(id);
//         if (inputElement) {
//             inputElement.addEventListener("change", function() {
//                 storeInLocalStorage(id, inputElement.value); 
//                 // console.log(id, inputElement.value); 
//             });
//         }
//     });

//     // Store all the form data in localStorage upon form submission
//     mktoForm.addEventListener("submit", function() {
//         inputIds.forEach(function(id) {
//             var inputElement = document.getElementById(id);
//             if (inputElement) {
//                 storeInLocalStorage(id, inputElement.value);
//             }
//         });
//     });
// }


/**
* MARKETO FORM DATA SCRAPER - Part 2 of 2 
* This second part needs to go on the destination page - currently this 
* uses a div that exists on https://www.sanabenefits.com/thank-you/, but 
* provided the user doesn't close their browser or use incognito mode the 
* data pulled into localStorage from the first script should be available.
*/
// window.onload = function() {
//     var heroSection = document.getElementById('hero-block_3dbb2f2ef0130ffa53d23f2ef9ddc09a');

//     if (!heroSection) {
//         return; // Exit the script if #hero-section doesn't exist
//     }

//     // Helper function to retrieve values from localStorage
//     function retrieveFromLocalStorage(fieldName) {
//         return localStorage.getItem(fieldName);
//     }

//     var inputIds = ["Email", "Phone", "FirstName", "LastName", "Company"];
//     var retrievedData = [];

//     // Retrieve data from localStorage
//     inputIds.forEach(function(id) {
//         var value = retrieveFromLocalStorage(id);
//         if (value) {
//             retrievedData.push(id + ": " + value);
//         }
//     });

//     // Create a new div inside #hero-block... 
//     var newDataDiv = document.createElement('div');

//     // Set the content of the new div using the retrieved data
//     newDataDiv.innerHTML = retrievedData.join('<br>');

//     // Set the div's style to display:none so it's not visible 
//     newDataDiv.style.display = "none";

//     // Append the new div to #hero-section
//     heroSection.appendChild(newDataDiv);
// }









// testing 
// window.onload = function() {
//     // Check if data exists in localStorage
//     if(localStorage.getItem('formData')) {
//         var data = JSON.parse(localStorage.getItem('formData'));

//         // Ensure all elements are present before trying to set values
//         if (document.getElementById('input_-CBTG6Rq-') && data.firstName) {
//             document.getElementById('input_-CBTG6Rq-').value = data.firstName;
//         }
//         if (document.getElementById('input_T91x98oP7D') && data.lastName) {
//             document.getElementById('input_T91x98oP7D').value = data.lastName;
//         }
//         if (document.getElementById('input_d-tHtXUMxD') && data.email) {
//             document.getElementById('input_d-tHtXUMxD').value = data.email;
//         }
//         if (document.getElementById('input_hzysW4PRiD') && data.company) {
//             document.getElementById('input_hzysW4PRiD').value = data.company;
//         }
//         if (document.getElementById('input_L_kAnomS6y') && data.phone) {
//             document.getElementById('input_L_kAnomS6y').value = data.phone;
//         }

//         // Clear the data from localStorage after use 
//         localStorage.removeItem('formData');
//     }
// };


